<template>
  <div>
    <v-sheet
      v-if="!departement.length"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>
    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.active"
        :bg-color="snackbar.color"
      ></snack-view>
      <div>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              offset-md="8"
              md="4"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Rechercher"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="departementList"
          :search="search"
          :options="dataOptions"
          :items-per-page="15"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- product -->
          <template #[`item.name`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
              </div>
            </div>
          </template>

          <template #[`item.id`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ departement.indexOf(item) + 1 }}</span>
              </div>
            </div>
          </template>

          <template #[`item.created_at`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ moment(item.created_at).format('D MMMM YYYY')  }}</span>
              </div>
            </div>
          </template>

          <template #[`item.updated_at`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ moment(item.updated_at).format('D MMMM YYYY')  }}</span>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <v-chip
                  v-if="item.state==1"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#F1FFE8FF"
                >
                  <b class="success--text">actif</b>
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2 text-center pr-5 pl-5"
                  color="#ffcfcf"
                >
                  <b class="error--text">bloqué</b>
                </v-chip>
              </div>
            </div>
          </template>

          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <div class="col-12 w-">
              <v-btn
                outlined
                x-small
                color="info"
                @click="editItem(item)"
              >
                <v-icon
                  x-small
                  class="me-2"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
                Modifier
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <!-- dialog édition -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span>Modifier un département</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-form
                      ref="updateForm"
                    >
                    <v-text-field
                      v-model="editedItem.name"
                      outlined
                      dense
                        label="Nom"
                        :rules="LocalNameRules"
                      ></v-text-field>

                      <v-text-field
                        v-model="editedItem.code"
                        outlined
                        dense
                        label="Code"
                        :rules="LocalNameRules"
                    ></v-text-field>

                      <v-text-field
                        v-model="editedItem.icon"
                      outlined
                      dense
                        label="Icône"
                        :rules="LocalNameRules"
                      ></v-text-field>
                      <v-col
                        cols="12"
                        md="12"
                        class="pr-0 mt-n8 pb-10"
                      >
                        <div
                          class="d-flex flex-row align-items-end"
                        >
                          <h3 class="mt-5 mr-2 font-weight-light">
                            <span>Etat </span>
                          </h3>
                          <v-switch
                            v-model="editedItem.status"
                            inset
                            color="success"
                          >
                            <template v-slot:label>
                              <span :class="editedItem.status?'success--text':'error--text'">
                                {{ editedItem.status?'actif':'bloqué' }}
                              </span>
                            </template>
                          </v-switch>
                        </div>
                      </v-col>
                      <!--                      <div-->
                      <!--                        class="d-sm-flex flex-sm-row-reverse align-content-end"-->
                      <!--                      >-->
                      <!--                        <v-radio-group-->
                      <!--                          v-model="editedItem.state"-->
                      <!--                          row-->
                      <!--                          hide-details-->
                      <!--                          class="align-sm-start mt-sm-0"-->
                      <!--                        >-->
                      <!--                          <v-radio-->
                      <!--                            key="0"-->
                      <!--                            label="Oui"-->
                      <!--                            :value="0"-->
                      <!--                            color="success"-->
                      <!--                          ></v-radio>-->
                      <!--                          <v-radio-->
                      <!--                            key="1"-->
                      <!--                            label="Non"-->
                      <!--                            :value="1"-->
                      <!--                            color="success"-->
                      <!--                          ></v-radio>-->
                      <!--                        </v-radio-group>-->
                      <!--                        <h3 class="mt-1 mr-2">-->
                      <!--                          <b>Bloqué ?</b>-->
                      <!--                        </h3>-->
                      <!--                      </div>-->
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog suppression -->
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import Http from '@/helpers/http'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import moment from 'moment'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'

Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
  },
  setup() {
    const search = ref('')
    const departement = ref([])
    const { router } = useRouter()
    const updateForm = ref(null)
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })

    const departementList = computed(() => departement.value)

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const loadDepartement = () => {
      Http.get('departement')
        .then(async response => {
          if (response.status === 200) {
            departement.value = await response.data
            console.log('departements')
            console.log(departement.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
      },
      search,
      headers: [
        { text: 'N°', value: 'id', divider: true },
        { text: 'Nom', value: 'name', divider: true },
        { text: 'Code', value: 'code', divider: true },
        { text: 'Icone', value: 'icon', divider: true },
        { text: 'Date de création', value: 'created_at', divider: true },
        { text: 'Dernière MAJ', value: 'updated_at', divider: true },
        { text: 'Etat', value: 'state', divider: true },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true,
        },
      ],
      dataOptions: {
        sortBy: ['id'],
      },
      departement,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogDelete: false,
      LocalNameRules,
      updateForm,
      snackbar,
      departementList,
      loadDepartement,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
  },
  mounted() {
    moment.locale('fr')
  },
  created() {
    this.loadDepartement()
  },
  methods: {
    editItem(item) {
      console.log(item)
      this.editedIndex = this.departement.indexOf(item)
      // eslint-disable-next-line no-param-reassign
      item.status = item.state === 1
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.departement.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.departement.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.$refs.updateForm.validate()) {
        this.snackbar.active = false
        this.editedItem.state = this.editedItem.status ? 1 : -1
        Http.put(`/departement/${this.editedItem.id}`, {
          name: this.editedItem.name, code: this.editedItem.code, icon: this.editedItem.icon, state: this.editedItem.state,
        })
        .then(response => {
            if (response.status === 201) {
          console.log(response.data)
              this.loadDepartement()
              this.snackbar.message = response.message
              this.snackbar.active = true
            }
        })
        .catch(error => {
          console.log(error)
        })

      this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
